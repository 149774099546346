<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-17 16:11:21
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-11-27 15:14:33
-->

<template>
  <el-container class="layout skin">
    <el-header class="navbar">
      <div class="navbar_wrp">
        <div class="logo">
          <img alt="logo" src="@/assets/logo.svg" />
        </div>
        <div class="menu"></div>
        <div class="right">
          <div class="item" v-if="is_can_flush_cache">
            <el-link :underline="false" @click="clearCache">清理缓存</el-link>
          </div>
          <div class="user">
            <div class="profile">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <el-avatar
                    :src="user.avatar_pic ? user.avatar_pic : avatar"
                  ></el-avatar>
                  <label>{{ user.name }}</label>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <router-link :to="{ path: '/profile' }">
                      <el-dropdown-item icon="el-icon-user"
                        >个人中心</el-dropdown-item
                      >
                    </router-link>
                    <el-dropdown-item
                      icon="el-icon-switch-button"
                      @click="onLogout"
                      >退出</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <side-bar :routes="routes"></side-bar>
      </el-aside>
      <div class="main_wrp">
        <el-breadcrumb separator="/">
          <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
          <el-breadcrumb-item v-for="item in breadList" :key="item">{{
            item.meta.title
          }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <el-main> -->
        <router-view></router-view>
        <!-- </el-main> -->
      </div>
    </el-container>
  </el-container>
</template>

<script>
import avatar from "@/assets/avatar.png";
import QrMsgIcon from "@/assets/qr_msg_icon.png";
import center_api from "../http/center_api";
import SideBar from "@/layout/sidebar";
export default {
  components: {
    SideBar,
  },
  data() {
    return {
      avatar: avatar,
      user: {},
      routes: [],
      //breadcrumb列表
      breadList: [],
      qr_msg_icon: QrMsgIcon,
      is_can_flush_cache: false,
    };
  },
  created() {
    // var json = localStorage.getItem("route_matched");
    // if (json) {
    //   var map = JSON.parse(json);
    //   if (map) {
    //     this.breadList = map;
    //   }
    // }
    this.getBreadcrumb();

    center_api.get_center().then((res) => {
      if (res.code == 0) {
        this.user = res.data;
        localStorage.setItem("user", JSON.stringify(res.data));
      }
    });
    /**
     * 获取我的菜单数据
     */
    center_api.get_menus().then((res) => {
      if (res.code == 0) {
        this.routes = res.data;
      }
    });

    /**
     *
     */
    center_api.get_apis().then((res) => {
      if (res.code == 0) {
        localStorage.setItem(this.$cfg.API_STORE_KEY, JSON.stringify(res.data));
        this.is_can_flush_cache = this.$power("admin/v1/setting/flushCache");
        if (this.$power("admin/v1/reportAlert/qrcode")) {
          this.loadQRCodeAlert();
        }
      }
    });
  },
  watch: {
    /**
     * 监听路由变化
     */
    $route() {
      this.getBreadcrumb();
    },
  },
  methods: {
    /**
     * 退出登录
     */
    onLogout() {
      this.$http.get("admin/v1/center/logout").then((res) => {
        if (res.code == 0) {
          localStorage.removeItem("token");
          this.$router.push({ name: "home" });
        }
      });
    },
    /**
     * 获取breadcrumb
     */
    getBreadcrumb() {
      if (
        this.$route.matched
      ) {
        this.breadList = this.$route.matched;
      } else {
        this.breadList = [];
        // localStorage.setItem('route_matched','');
      }
    },

    /**
     * 清空缓存
     */
    clearCache() {
      this.$confirm("您确定要清除系统所有缓存？", "温馨提示")
        .then(() => {
          this.$http.get("admin/v1/setting/flushCache").then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
            }
          });
        })
        .catch(() => {});
    },

    /**
     * 检测新二维码申请
     */
    loadQRCodeAlert() {
      var self = this;
      setInterval(function () {
        if (Notification.permission === "granted") {
          self.$http.get("admin/v1/reportAlert/qrCode").then((res) => {
            if (res.code == 0) {
              if (res.data && res.data.length) {
                var item = res.data[0];
                var notification = new Notification("新的二维码申请", {
                  dir: "auto",
                  lang: "zh-CN",
                  tag: "get-qrCodeNotice",
                  icon: self.qr_msg_icon,
                  body: `项目：${item.estateName}，客户：${item.clientName}，处理人：${item.userName}`,
                });
                setTimeout(function () {
                  notification.close();
                }, 6000);
              }
            }
          });
        } else {
          Notification.requestPermission();
        }
      }, 10000);
    },
  },
};
</script>

<style scoped>
/* $aside-width: 200px; */

/* // $themBgColor:#42b983; */
.skin {
  height: 100%;
  --aside-width: 200px;
}

.skin .el-aside {
  background-color: #f2f2f2;
  height: 100%;
}

.navbar {
  border-bottom: 1px solid var(--el-border-color);
  background: white;
}

.navbar_wrp {
  display: flex;
  align-items: center;
  height: 100%;
}
.navbar_wrp .logo {
  height: 60%;
  width: 180px;
}
.navbar_wrp .logo img {
  height: 100%;
  font-size: 0;
}
.navbar .menu {
  flex: 1;
}

.navbar .right {
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar .right .item {
  height: 100%;
}
.navbar .right .item a {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 20px;
}
.navbar .right .user {
  display: flex;
  align-items: center;
}
.navbar .right .user .el-dropdown-link {
  display: flex;
  align-items: center;
}
.navbar .right .user .el-avatar {
  margin-right: 5px;
}

.navbar + .el-container {
  /* flex: 1; */
  overflow: auto;
}
</style>
